var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "modal__header" }, [
      _vm._m(0),
      _c(
        "div",
        [
          _c("z-btn", {
            attrs: { primary: "", text: "+ Tag" },
            on: { click: _vm.addAnotherTag },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "modal__body" }, [
      _c(
        "div",
        { staticClass: "modal__body__select mb-3" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "mx-2",
                  staticStyle: { width: "100%" },
                  attrs: { "lazy-validation": "" },
                },
                _vm._l(Object.keys(_vm.localTags), function (el) {
                  return _c(
                    "v-col",
                    {
                      key: el,
                      attrs: { cols: "12", lg: "6", md: "6", sm: "12" },
                    },
                    [
                      _c("z-input", {
                        ref: "tagname",
                        refInFor: true,
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: {
                          hideDetails: true,
                          label: "Nome da tag " + el,
                          type: "text",
                        },
                        model: {
                          value: _vm.localTags[parseInt(el)],
                          callback: function ($$v) {
                            _vm.$set(_vm.localTags, parseInt(el), $$v)
                          },
                          expression: "localTags[parseInt(el)]",
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal--actions" },
      [
        _c("z-btn", {
          attrs: { text: "Cancelar" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
        _c("z-btn", {
          attrs: { primary: "", text: "confirmar" },
          on: { click: _vm.closeModal },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header__left" }, [
      _c("span", [_vm._v("Adicionar Tags")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }