<template>
  <div class="wrapper">
    <div class="wrapper__card">
      <div class="wrapper__card__header">
        <div class="left">
          <div>
            <z-btn
              icon="$settings_rounded"
              primary
              small
              rounded
              text="configurar jornada"
              @click="configurationDialog = true"
            />
          </div>
          <div class="inpt">
            <v-form ref="formname">
              <z-input
                v-model="journey.name"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                :hide-details="true"
                label="Nome da Jornada"
              />
            </v-form>
          </div>

          <div class="icons">
            <v-icon :disabled="!isDisabledUndo" @click="moveStep(false)"
              >$undo_rounded</v-icon
            >
            <v-icon :disabled="!isDisabledRedo" @click="moveStep(true)">
              $redo_rounded
            </v-icon>
          </div>
        </div>

        <div class="right">
          <div>
            <z-btn
              @click="saveJourney(false)"
              :is-loading="loading"
              small
              rounded
              text="salvar jornada"
            />
          </div>
          <!--          <div>-->
          <!--            <z-btn-->
          <!--              @click="saveDraftJourney"-->
          <!--              :is-loading="loading"-->
          <!--              small-->
          <!--              rounded-->
          <!--              text="salvar rascunho"-->
          <!--              :disabled="-->
          <!--                !journey.name ||-->
          <!--                !tree ||-->
          <!--                !journeyRecorrenceConfigured-->
          <!--              "-->
          <!--            />-->
          <!--          </div>-->
          <div>
            <z-btn
              @click="validateJourneySteps(treeTest)"
              small
              rounded
              text="validar"
            />
          </div>
          <div>
            <z-btn
              icon="$help_outline_rounded"
              small
              rounded
              text="ajuda"
              @click="helpDialog = true"
            />
          </div>
        </div>
      </div>
      <div class="wrapper__card__body">
        <div class="wrapper__card__body__content__center">
          <button
            v-if="data.nodes.length === 0"
            type="button"
            style="z-index: 5; position: absolute; left: 40%; cursor: pointer"
            @click="createDefaultJourney()"
          >
            Clique para adicionar uma nova jornada
          </button>
        </div>
        <div
          class="wrapper__card__body__content"
          style="padding-left: 10px"
          v-if="treeTest != null"
        >
          <TreeComponent
            v-if="treeTest != null && render"
            :nodes="treeTest"
            :key="keyComponent"
            :database-config-id-prop="databaseConfigId"
            @linkClick="clickLink"
            @yes_click="yes_click"
            @no_click="no_click"
            @node_click="nodeClick"
            @node_click_right="setNodeToRemove"
            @rename_node="setNodeToRename"
            @joinInformations="joinInformations"
          />
        </div>
      </div>

      <v-dialog
        v-model="configurationDialog"
        width="540"
        style="border-radius: 15px !important; z-index: 10 !important"
      >
        <ConfigurationModal
          :timerInfosProp="objFastJourney || null"
          @close="closeConfigurationModal"
        />
      </v-dialog>

      <v-dialog
        v-model="startJourneyDialog"
        width="400"
        style="border-radius: 15px !important; z-index: 99999 !important"
      >
        <StartJourneyModal
          v-if="startJourneyDialog"
          :is-audience-prop="isAudienceClicked"
          :father-diamond-clicked="currentDiamondOrChildDiamondClicked"
          @close="closeStartJourneyModal"
          @clicked="startJourneyClickItem"
        />
      </v-dialog>

      <v-dialog
        v-model="helpDialog"
        width="400"
        style="border-radius: 15px !important; z-index: 99999 !important"
      >
        <HelpModal v-if="helpDialog" @close="helpDialog = false" />
      </v-dialog>

      <v-dialog
        v-model="abtestDialog"
        width="400"
        style="border-radius: 15px !important; z-index: 99999 !important"
      >
        <AbTestModal
          v-if="abtestDialog"
          :clickedNode="clickedNode"
          @close="closeAbTestModal"
        />
      </v-dialog>

      <v-dialog
        v-model="configureAwaitDialog"
        width="350"
        style="border-radius: 15px !important; z-index: 99999 !important"
      >
        <ConfigureAwaitModal
          v-if="configureAwaitDialog"
          @close="inserirIntervalo"
        />
      </v-dialog>

      <v-dialog v-model="datePediodDialog" width="300">
        <DatePeriodModal v-if="datePediodDialog" />
      </v-dialog>

      <v-dialog
        v-model="selectAudienceDialog"
        width="1100"
        style="z-index: 99999 !important"
      >
        <SelectAudienceModal
          v-if="selectAudienceDialog"
          :node-prop="clickedNode"
          :audience-id-prop="journey.audienceId"
          @close="closeSelectAudienceModal"
          @editSelectedAudience="editSelectedAudience"
          @newAudience="newAudience('audience')"
        />
      </v-dialog>

      <v-dialog
        v-model="selectTemplateDialog"
        :width="templateModalWidth"
        style="z-index: 99999 !important"
      >
        <SelectTemplateModal
          v-if="selectTemplateDialog"
          :typeTemplate="typeTemplate"
          :node-prop="clickedNode"
          :databaseConfigId="databaseConfigId"
          @close="closeSelectTemplateModal"
          @changeSizeModal="changeSizeModal"
        />
      </v-dialog>

      <v-dialog
        v-model="selectPushDialog"
        :width="templateModalWidth"
        style="z-index: 99999 !important"
      >
        <SelectPushModal
          v-if="selectPushDialog"
          :node-prop="clickedNode"
          :databaseConfigId="databaseConfigId"
          @close="closePushModal"
          @changeSizeModal="changeSizeModal"
        />
      </v-dialog>

      <v-dialog
        v-model="filteredAudienceDialog"
        width="800"
        style="z-index: 99999 !important"
      >
        <FilteredAudienceModal
          :dbConnectionId="databaseConfigId"
          v-if="filteredAudienceDialog"
          @close="closeFilteredAudienceModal"
          @createNewFilter="newAudience('filter')"
        />
      </v-dialog>

      <v-dialog
        v-model="renameNodeDialog"
        width="500"
        style="z-index: 99999 !important"
      >
        <RenameNodeModal
          v-if="renameNodeDialog"
          @close="
            renameNodeDialog = false;
            nodeToRename = null;
          "
          @rename="setNewNodeName"
        />
      </v-dialog>

      <v-dialog
        v-model="mensageriaCheckDialog"
        width="500"
        style="z-index: 99999 !important"
      >
        <MensageriaCheckModal
          v-if="mensageriaCheckDialog"
          :current-node-clicked-prop="clickedNode"
          :mensageriaSteps="messageriaStepsItems"
          @close="closeMensageriaCheckModal"
        />
      </v-dialog>

      <v-dialog
        v-model="divisionChooseDialog"
        width="500"
        persistent
        style="z-index: 99999 !important"
      >
        <DivisionChooseModal
          v-if="divisionChooseDialog"
          @close="divisionChooseDialog = false"
          @chooseDivision="setDivisionTypeInNode"
        />
      </v-dialog>

      <v-dialog
        v-model="cofirmationModalDialog"
        width="500"
        style="z-index: 99999 !important"
      >
        <ConfirmationLeavePageModal
          v-if="cofirmationModalDialog"
          @close="cofirmationModalDialog = false"
          @confirmLeave="closeConfirmationLeavePage"
        />
      </v-dialog>

      <v-dialog
        v-model="deleteNodeDialog"
        width="500"
        style="z-index: 99999 !important"
      >
        <ConfirmDeleteNodeModal
          v-if="deleteNodeDialog"
          @close="
            deleteNodeDialog = false;
            nodeToRemove = null;
          "
          @remove="
            nodeClickRight(nodeToRemove);
            deleteNodeDialog = false;
            nodeToRemove = null;
          "
        />
      </v-dialog>

      <v-dialog
        v-model="chooseStepToCreateDialog"
        width="400"
        style="z-index: 99999 !important"
      >
        <ChooseStepToCreate
          v-if="chooseStepToCreateDialog"
          @close="chooseStepToCreateDialog = false"
          @select="selectStepToJoin"
        />
      </v-dialog>

      <v-dialog
        v-model="draftModal"
        width="500"
        style="z-index: 99999 !important"
      >
        <ConfirmSaveDraftModal
          :journey="this.journey"
          v-if="draftModal"
          @close="draftModal = false"
          @confirm="saveDraftJourney"
        />
      </v-dialog>

      <v-dialog
        v-model="showModalIgnoreOptout"
        style="z-index: 99999 !important"
        width="400"
      >
        <ConfirmOptoutJourney
          v-if="showModalIgnoreOptout"
          @close="showModalIgnoreOptout = false"
          @confirm="confirmJourneyWithOptout"
        />
      </v-dialog>
      <v-dialog v-model="colectionNameDialog" width="600">
        <ColectionNameModal
          :actual-node-prop="clickedNode"
          :databaseConfigId="databaseConfigId"
          v-if="colectionNameDialog"
          @close="closeColectionModal"
        />
      </v-dialog>
      <v-dialog v-model="tagUserDialog" width="600">
        <TagUserModal
          v-if="tagUserDialog"
          :tags="stepTags"
          @close="closeTagModal"
        />
      </v-dialog>

      <v-dialog v-model="colectionNameDialogGoogle" width="600">
        <ColectionNameModal
          v-if="colectionNameDialogGoogle"
          :actual-node-prop="clickedNode"
          :databaseConfigId="databaseConfigId"
          :isGoogleModal="true"
          @close="closeColectionModal"
        />
      </v-dialog>

      <v-dialog
        v-model="selectWppDialog"
        :width="templateModalWidth"
        style="z-index: 99999 !important"
      >
        <SelectWppModal
          v-if="selectWppDialog"
          :node-prop="clickedNode"
          :databaseConfigId="databaseConfigId"
          @close="closeWppModal"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import journeyMixin from "./journeyMixin";
import TreeComponent from "./components/fluxogram/ExampleList.vue";
import ConfigurationModal from "./components/configurationModal2.vue";
import ConfigureAwaitModal from "./components/configureAwaitModal.vue";
import ChooseStepToCreate from "./components/chooseStepToCreate.vue";
import StartJourneyModal from "./components/startJourneyModal.vue";
import HelpModal from "./components/helpModal.vue";
import DatePeriodModal from "./components/datePeriodModal.vue";
import SelectAudienceModal from "./components/selectAudienceModal.vue";
import SelectTemplateModal from "./components/selectTemplateModal.vue";
import SelectPushModal from "./components/selectPushModal.vue";
import SelectWppModal from "./components/selectWppModal.vue";
import FilteredAudienceModal from "./components/filteredAudienceModal.vue";
import AbTestModal from "./components/abTestModal.vue";
import TagUserModal from "./components/tagUserModal.vue";
import RenameNodeModal from "./components/renameNodeModal.vue";
import ConfirmDeleteNodeModal from "./components/confirmDeleteNodeModal.vue";
import MensageriaCheckModal from "./components/mensageriaCheckModal.vue";
import DivisionChooseModal from "./components/divisionChooseModal.vue";
import ConfirmationLeavePageModal from "./components/confirmationLeavePageModal.vue";
import ConfirmSaveDraftModal from "./components/confirmSaveDraftModal.vue";
import ConfirmOptoutJourney from "./components/confirmOptoutJourney.vue";
import ColectionNameModal from "./components/colectionNameModal.vue";
import { fluxoGramMock, fluxoGramMock2 } from "./data";
import "vue-light-flowchart/dist/vue-light-flowchart.css";
import ApiClient from "@/services/ApiClient";

export default {
  mixins: [journeyMixin],
  components: {
    TreeComponent,
    ConfirmDeleteNodeModal,
    ConfigurationModal,
    StartJourneyModal,
    HelpModal,
    DatePeriodModal,
    SelectAudienceModal,
    SelectTemplateModal,
    SelectPushModal,
    FilteredAudienceModal,
    ConfigureAwaitModal,
    AbTestModal,
    RenameNodeModal,
    MensageriaCheckModal,
    DivisionChooseModal,
    ConfirmationLeavePageModal,
    ChooseStepToCreate,
    ConfirmSaveDraftModal,
    ConfirmOptoutJourney,
    ColectionNameModal,
    SelectWppModal,
    TagUserModal
  },
  data() {
    return {
      selectWppDialog: false,
      colectionNameDialog: false,
      colectionNameDialogGoogle: false,
      selectPushDialog: false,
      basicTemplateToCreateJourney: null,
      basicAudienceToCreateJourney: null,
      showModalIgnoreOptout: false,
      currentMessageriaStepsItems: [],
      isToUpdateDiamondToJoin: null,
      currentInformation: {},
      journeyStepsCount: [],
      currentJourneyStepIndex: -1,
      currentDiamondOrChildDiamondClicked: false,
      nodeToBeMerged: null,
      chooseStepToCreateDialog: false,
      isToUpdateDiamond: false,
      objFastJourney: null,
      divisionChooseDialog: false,
      navigateToRoute: null,
      isSaved: false,
      isEditingSomeAudience: false,
      cofirmationModalDialog: false,
      nodeToRemove: null,
      deleteNodeDialog: false,
      renameNodeDialog: false,
      clickedNode: null,
      nodeToRename: null,
      isAudienceClicked: false,
      keyComponent: 0,
      nos: [],
      typeTemplate: "",
      templateModalWidth: "1300",
      abtestDialog: false,
      fluxoGramMock,
      draftModal: false,
      fluxoGramMock2,
      configurationDialog: false,
      startJourneyDialog: false,
      tagUserDialog: false,
      helpDialog: false,
      datePediodDialog: false,
      selectAudienceDialog: false,
      selectTemplateDialog: false,
      configureAwaitDialog: false,
      filteredAudienceDialog: false,
      http: new ApiClient(),
      rect: { x: 50, y: 50, width: 100, height: 50 },
      startPoint: { x: 50, y: 100 },
      endPoint: { x: 150, y: 100 },
      data: {
        orientation: "hor",
        centerX: 1024,
        centerY: 140,
        scale: 1,
        nodes: [],
        links: [],
      },
      stepTags: null,
      databaseConfigId: null,
      clickedNodeId: null,
      rightclickedNodeId: null,
      justClickedLink: false,
      insertIndexPosition: null,
      tree: {},
      treeTest: {},
      recurrencyConfiguration: {},
      treeGrid: [],
      newNodeFromNextOrYesClick: "next",
      journeyConfiguration: {
        init: "",
        end: null,
        repeat: false,
        runTimes: 1,
        interval: "",
        executeTime: "",
        timezone: "gmt-3",
      },
      journey: {
        name: null,
        ignoreOptout: false,
        cron: "",
        audienceId: null,
        shouldSentMensageriaToExistentContact: false,
      },
      render: true,
      lastAudience: null,
      lastAudienceType: null,
      arrValidationSteps: [],
      journeyRecorrenceConfigured: false,
      journeyValidation: {
        steps: false,
        recurrency: false,
        name: false,
        templateSteps: false,
      },
      loading: false,
      mensageriaCheckDialog: false,
      isEditingJourney: false,
      currentClickedNodeId: null,
    };
  },
  provide() {
    return {
      databaseConfigId: computed(() => this.databaseConfigId),
      isToUpdateDiamond: computed(() => this.isToUpdateDiamond),
      currentClickedNodeId: computed(() => this.currentClickedNodeId),
      isToUpdateDiamondToJoin: computed(() => this.isToUpdateDiamondToJoin),
      clickedNodesList: [],
    };
  },
  computed: {
    hasSomeUpdate() {
      return (
        this.journey?.name?.length > 0 ||
        this.journey?.audienceId?.length > 0 ||
        this.tree?.id?.length > 0
      );
    },
  },
  watch: {
    mensageriaCheckDialog(nv) {
      // sempre que o step de mensageria modal for pra false, tenho que esvaziar o array local
      // para ele não ficar inserindo informação repetida
      if (!nv) this.currentMessageriaStepsItems = [];
    },
    startJourneyDialog(nv) {
      if (!nv) this.currentDiamondOrChildDiamondClicked = false;
    },

    arrValidationSteps(val) {
      if (val.length === 0) {
        this.journeyValidation.steps = true;
      } else {
        this.journeyValidation.steps = false;
      }
    },
  },
  created() {
    const _ = require("lodash");

    const AUDIENCE_INFO = this.$store.getters["app/$currentAudienceInfo"];
    const BASIC_JOURNEY_TO_CREATE =
      this.$store.getters["app/$defaultNodeToCreate"];

    if (AUDIENCE_INFO) {
      this.journey = AUDIENCE_INFO.journey;
      this.tree = AUDIENCE_INFO.steps;
      this.treeTest = AUDIENCE_INFO.steps;
      this.data.nodes = AUDIENCE_INFO.nodes;
      (this.clickedNodeId = AUDIENCE_INFO.nodeClickId),
        (this.journeyStepsCount = _.cloneDeep(AUDIENCE_INFO.stepsCount));
      this.currentJourneyStepIndex = AUDIENCE_INFO.stepsIndex;
      this.findNodeAndReplaceWithNewInfos(
        AUDIENCE_INFO.nodeClickId,
        AUDIENCE_INFO.audience,
        AUDIENCE_INFO.filterType,
        AUDIENCE_INFO.currentDatabaseConfigId
      );
    }
    console.log(BASIC_JOURNEY_TO_CREATE);
    if (BASIC_JOURNEY_TO_CREATE.isToCreate) {
      this.basicTemplateToCreateJourney = BASIC_JOURNEY_TO_CREATE.node.template;
      this.basicAudienceToCreateJourney = BASIC_JOURNEY_TO_CREATE.node.audience;
      // console.log("BASIC_JOURNEY_TO_CREATE.node.mountObj: ", BASIC_JOURNEY_TO_CREATE.node.mountObj)
      // this.objFastJourney = BASIC_JOURNEY_TO_CREATE.node.mountObj;
      // console.log("objFastJourney: ", this.objFastJourney)
      this.closeConfigurationModal(BASIC_JOURNEY_TO_CREATE.node.mountObj);
      this.basicTemplateToCreateJourney.templateId =
        this.basicTemplateToCreateJourney.id;
      this.basicTemplateToCreateJourney.wasRenamed = false;
      this.basicAudienceToCreateJourney.wasRenamed = false;

      if (this.basicTemplateToCreateJourney.templateType == "sms") {
        this.basicTemplateToCreateJourney.mailSender =
          this.basicTemplateToCreateJourney.emailSender;
        this.basicTemplateToCreateJourney.mailSubject =
          this.basicTemplateToCreateJourney.emailSubject;
      }

      this.createBasicJourney();
    }

    this.$store.dispatch("app/RESET_CURRENT_AUDIENCE_INFO");
  },
  mounted() {},
  methods: {
    invalidateJourneySteps(objeto, id) {
      if (objeto.id === id) {
        return (objeto.shouldValidate = false);
      }

      if (objeto.nextNode) {
        this.invalidateJourneySteps(objeto.nextNode, id);
      }

      if (objeto.YesNext) {
        this.invalidateJourneySteps(objeto.YesNext, id);
      }

      const sub = { ...this.treeTest };
      this.treeTest = null;
      this.treeTest = { ...sub };
      this.render = false;
      this.render = true;
      this.$forceUpdate();
      return "invalidated";
    },

    validateJourneySteps(objeto) {
      this.doValidateJourneySteps(objeto);

      if (
        this.arrValidationSteps.length === 0 &&
        this.journey.name &&
        this.journey.audienceId &&
        this.tree &&
        this.journeyRecorrenceConfigured &&
        this.journeyValidation.templateSteps
      ) {
        this.$toast.success("Jornada validada com sucesso");
      } else {
        this.showMessageForValidation();
      }
    },

    async saveDraftJourney() {
      this.loading = true;
      this.journey.steps = this.tree;
      this.journey.recurrencyConfiguration = JSON.stringify(
        this.recurrencyConfiguration
      );
      this.journey.shouldSentMensageriaToExistentContact = await this.http
        .post("journey/draft", this.journey)
        .then(() => {
          this.loading = false;
          this.isSaved = true;
          this.$toast.success("Jornada salva com sucesso!");
          this.$router.push("/journey/created");
        })
        .catch((err) => {
          this.loading = false;
          this.$toast.error("Ocorreu um erro ao tentar salvar a sua jornada.");
          throw err;
        });
    },

    async confirmJourneyWithOptout() {
      this.saveJourney(true);
    },

    async saveJourney(ignoreOptoutValidation = false) {
      if (!this.journey.name) {
        this.$toast.error("Para salvar adicione um nome a sua jornada.");
      } else {
        if (this.journey.ignoreOptout && !ignoreOptoutValidation) {
          this.showModalIgnoreOptout = true;
          return;
        }
        this.doValidateJourneySteps(this.treeTest);
        if (
          this.journey.name &&
          this.journey.audienceId &&
          this.tree &&
          this.arrValidationSteps.length == 0 &&
          this.journeyRecorrenceConfigured &&
          this.journeyValidation.templateSteps
        ) {
          this.loading = true;
          this.journey.steps = this.tree;
          this.journey.recurrencyConfiguration = JSON.stringify(
            this.recurrencyConfiguration
          );
          this.journey.shouldSentMensageriaToExistentContact = await this.http
            .post("journey/create", this.journey)
            .then(() => {
              this.loading = false;
              this.isSaved = true;
              this.$toast.success("Jornada salva com sucesso!");
              this.$router.push("/journey/created");
            })
            .catch((err) => {
              this.loading = false;
              console.log('dasdsaasds');
              console.log(err.body.detail);

              if (err && err.body && err.body.detail) this.$toast.error(err.body.detail);
              else
                this.$toast.error(
                  "Ocorreu um erro ao tentar salvar a sua jornada."
                );
              throw err;
            });
        } else {
          // console.log(this.arrValidationSteps, this.journeyValidation);
          this.draftModal = true;
        }
      }
    },

    async createBasicJourney() {
      if (this.basicTemplateToCreateJourney) {
        // 1 - CRIAR JORNADA DEFAULT INICIALMENTE
        await this.createDefaultJourney(false);

        // 1.1 COLOCAR NA JORNADA A  AUDIÊNCIA RECEBIDA
        const audience_node = await this.findAndReturnSomeNode(
          this.tree,
          "selectAudience"
        );

        this.clickedNodeId = audience_node.id;

        this.closeSelectAudienceModal(this.basicAudienceToCreateJourney);

        // 2- SIMULAR CLIQUE NO LINK PARA INSERÇÃO DE INFORMAÇÕES
        this.newNodeFromNextOrYesClick = "next";
        this.insertIndexPosition = this.tree.id;
        this.isAudienceClicked = false;

        // 3 - CRIAR LINK/NÓ COM O TIPO DO NÓ RECEBIDO O COLOCANDO NA POSIÇÃO DO NÓ CLICADO (insertIndexPosition)
        let info = {};

        if (this.basicTemplateToCreateJourney.templateType == "sms") {
          info = {
            name: "Enviar SMS",
            imgPath: "/icons/send-sms-icon.svg",
          };
        }

        if (this.basicTemplateToCreateJourney.templateType == "email") {
          info = {
            name: "Enviar e-mail",
            imgPath: "/icons/send-email-icon.svg",
          };
        }

        if (this.basicTemplateToCreateJourney.templateType == "push") {
          info = {
            name: "Disparo via push",
            imgPath: "/icons/send-push-icon.svg",
          };
        }

        this.startJourneyClickItem(info);

        // NECESSÁRIO ENCONTRAR O ID DO NÓ DE TEMPLATE PARA PASSAR NO clickedNodeId
        const type = info.imgPath.includes("sms")
          ? "sendSMS"
          : info.imgPath.includes("email")
          ? "sendEmail"
          : "sendPush";

        const t_node = await this.findAndReturnSomeNode(this.tree, type);

        if (t_node) this.clickedNodeId = t_node.id;

        // 4 - INSERIR INFORMAÇÃO NO CHIP CRIADO
        this.closeSelectTemplateModal(this.basicTemplateToCreateJourney);

        this.$store.dispatch("app/RESET_BASIC_JOURNEY");
      }
    },

    async createDefaultJourney() {
      const DEFAULT_JOURNEY = {
        name: "Audiência",
        imgPath: "/icons/purple-audience-icon.svg",
      };

      await this.startJourneyClickItem(DEFAULT_JOURNEY);
      this.nodeClick(this.tree.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.flowchart-container {
  height: 100% !important;
  width: 100% !important;
}

#position,
.unselectable {
  display: none !important;
  color: white;
}

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
      padding: $z-s-1;
      background: #ffffff;
      border-radius: 15px;
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-1;
      flex-wrap: wrap;

      .left {
        flex: 2 1 auto;
        display: flex;

        align-items: center;
        gap: $z-s-1 0.5rem;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }

        .inpt {
          width: 100%;
        }
        .icons {
          display: flex;
          align-items: center;
          gap: $z-s-1 0.5rem;
        }

        @media (max-width: 750px) {
          flex-wrap: wrap;
        }
      }
      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }
    &__body {
      border: 2px dashed $z-black-1;
      border-radius: 15px;
      padding: $z-s-3 $z-s-1;
      max-height: 100%;
      flex: 1 1 100%;
      overflow-y: auto;
      @include trackScrollBar;
      display: flex;
      align-items: center;
      padding: 0px !important;
      padding: 0 10px !important;
      &__content {
        display: flex;
        align-items: start;
        padding-top: 35px;
        overflow: scroll;
        height: 100% !important;
        width: 100% !important;
        &__center {
          padding-top: 35px;
          align-items: center;
        }
      }
    }
  }
}
</style>
